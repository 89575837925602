@import "src/assets/styles/common";

.page {
  padding-top: 101px;
}

.section-content {
  overflow: hidden;
}

@include mobile {
  .page {
    padding-top: 74px;
  }
}

//region HERO
.hero-section {
  background-color: $gray-1;
  overflow-x: hidden;
}

.hero-section .hero-content {
  overflow: visible;
}

.hero-wrapper {
  @include flex(center, space-between);

  padding: 60px 80px 60px 0;

  @include more-than-desktop {
    padding: 120px 80px 120px 0;
  }

  @include tablet-portrait {
    padding-right: 0;
  }

  @include mobile {
    flex-direction: column;

    height: auto;
    padding: 50px 0;
  }
}

.hero-blur {
  position: absolute;
  bottom: 0;
  right: 0;

  height: 150%;

  z-index: 1;

  pointer-events: none;

  @include mobile {
    height: auto;
  }
}

.hero-data,
.hero-media {
  position: relative;
  z-index: 2;
}

.hero-data {
  @include mobile {
    margin-bottom: 40px;
  }
}

.hero-title {
  @include font-barlow(60px, 65px, $font-bold);
  margin-bottom: 32px;

  width: 550px;

  color: #012E58;

  @include beyond-desktop {
    width: 637px;
  }

  @include tablet-portrait {
    @include font-barlow(46px, 48px, $font-bold);
    width: 80%;
  }

  @include mobile {
    width: auto;

    text-align: center;
  }
}

.hero-text {
  @include font-barlow(20px, 28px);
  margin-bottom: 32px;

  width: 600px;

  color: $gray-8;

  @include tablet-portrait {
    @include font-barlow(18px, 24px);
    width: 80%;
  }

  @include mobile {
    width: auto;

    text-align: center;
  }
}

.hero-icons {
  @include flex;
  flex-wrap: wrap;
  gap: 8px;

  width: 326px;

  margin-bottom: 32px;

  @include mobile {
    @include flex(center, center);
    margin: 0 auto 32px;
  }
}

.hero-tooltip {
  box-shadow: 0 0 16px 1px rgba(0,0,0,0.2);
}

.hero-icon {
  @include size(46px);
}

.hero-actions {
  @include flex(center);
  position: relative;

  .btn {
    display: inline-block;
  }

  @include mobile {
    @include flex(center, center);

    .btn {
      width: 295px;
      text-align: center;
    }
  }
}

.hero-actions-text {
  @include font-nanum-pen(16px, 18px);
  color: $gray-8;
  width: 175px;

  padding: 6px 0;

  margin-left: 44px;
}

@keyframes arrow-fill {
  from {
    stroke-dashoffset: -1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.hero-actions-arrow {
  position: absolute;
  width: 140px;

  bottom: -100px;
  left: 100px;

  stroke-dasharray: 1000;
  stroke-dashoffset: -1000;

  color: $gray-9;

  &.fade-done {
    stroke-dashoffset: 0;
    animation: arrow-fill 1000ms ease-out 0ms;
  }
}

.hero-actions-text,
.hero-actions-arrow {
  @include mobile {
    display: none;
  }
}

@keyframes hero-media-in {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(15deg);
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0);
  }
}

.hero-media {
  opacity: 0;

  &.fade-done {
    animation-name: hero-media-in;
    animation-timing-function: ease-in-out;
    animation-duration: 1600ms;
  }
}

@keyframes bubble-fade {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-media-bubble {
  @include font-barlow(13.4px, 15.4px);

  position: absolute;
  display: inline-block;

  border-radius: 24px 24px 0 24px;
  padding: 16px;
  background: $gray-4;

  width: auto;

  transition: opacity 400ms ease-in-out 15ms, transform 400ms ease-in-out 15ms;
  opacity: 0;
  transform: translateY(100%);

  z-index: 2000;

  &.__show {
    opacity: 1;
    transform: translateY(0);

    animation-name: bubble-fade;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
  }
}

.bubble-1 {
  top: 205px;
  left: -90px;

  @include beyond-desktop {
    top: 225px;
  }

  @include mobile {
    left: -50px;
  }
}
.bubble-2 {
  top: 245px;
  left: -50px;

  @include beyond-desktop {
    top: 290px;
  }
}
.bubble-3 {
  top: 260px;
  left: -50px;

  @include beyond-desktop {
    top: 295px;
  }
}

.hero-square {
  position: absolute;
  width: 220px;
  height: 270px;

  transform: translateZ(10px);

  opacity: 0;

  &.animation-done {
    opacity: 1;
  }

  @include mobile {
    display: none;
  }
}

.square-1 {
  left: -200px;
  bottom: 100px;
}
.square-2 {
  right: -200px;
  bottom: 200px;
}
.square-3 {
  right: -100px;
  bottom: -50px;
}

@keyframes rotate-fade {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

.hero-media.fade-done {
  .square-1,
  .square-2 {
    animation-name: rotate-fade;
    animation-duration: 800ms;
    animation-delay: 800ms;
    animation-timing-function: ease-in-out;
  }

  .square-3 {
    animation-name: fade-up-animation;
    animation-duration: 800ms;
    animation-delay: 1000ms;
    animation-timing-function: ease-in-out;
  }
}

.hero-video {
  position: relative;
  display: block;
  @include size(100%);

  transform: translateZ(40px);
  z-index: 10;

  .video-container-el {
    border-radius: 16px;
    object-fit: fill;
    object-position: center;
  }

  .video-container-overlay, .video-container-loader {
    border-radius: 16px;
  }
}
//endregion

//region GROW
.grow-section {
  background: linear-gradient(#2a3997 25%, #5f34b2 75%)
}

.grow-section .grow-content {
  padding: 0;
  max-width: unset;
}

.grow-bg {
  z-index: 1;
}

.grow-video {
  display: block;
  width: 100%;

  min-height: 810px;

  @include mobile {
    min-height: 666px;
  }
}

.grow-ref {
  display: none;
}

.grow-data {
  position: absolute;
  top: 60px;
  left: 50%;

  width: 100%;

  transform: translateX(-50%);

  @include mobile {
    top: 32px;
  }
}

.grow-title,
.grow-text {
  position: relative;
  z-index: 2;

  @include mobile {
    padding: 0 20px;
  }
}

.grow-title {
  @include font-barlow(48px, 50px, $font-black);
  text-align: center;

  margin-bottom: 16px;

  color: $gray-1;

  .highlight {
    @include text-highlight(linear-gradient(63.46deg, #69C0FF 27%, #096DD9 78.28%));
  }

  @include mobile {
    @include font-barlow(32px, 41px, $font-black);

    .grow-title-main {
      @include font-barlow(48px, 48px, $font-black);
    }
  }
}

.grow-text {
  @include font-lato(18px, 35px);
  color: $gray-5;

  width: 600px;

  text-align: center;

  margin: 0 auto;

  @include mobile {
    @include font-lato(18px, 26px);
    color: $gray-6;
    width: auto;
  }
}

//endregion

//region FEATURES
.features-section {
  position: relative;
  background: linear-gradient(180deg, #012052 0%, #011432 100%);
}
.features-section .features-content {
  padding-top: 165px;
  padding-bottom: 165px;

  @include mobile {
    padding-top: 40px;
    padding-bottom: 40px
  }
}

.features-block {
  position: relative;
}

.features-blur-1 {
  position: absolute;
  top: 0;
  left: 0;

  width: 650px;
}

.features-blur-2 {
  position: absolute;
  top: 0;
  right: 0;

  width: 600px;
}

.features-blur-3 {
  position: absolute;
  bottom: 400px;
  left: 0;
  width: 800px;
}


.features-blur-4 {
  position: absolute;
  bottom: 0;
  left: 42%;
  width: 1000px;

  @include mobile {
    left: -190px;
    bottom: 10px;
    width: 200%;
  }
}

.features-item {
  position: relative;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 180px;
  }

  @include mobile {
    &:not(:last-of-type) {
      margin-bottom: 100px;
    }
  }
}

.features-one {
  @include flex(flex-start, flex-end);
  position: relative;

  width: 100%;
  max-width: 500px;

  @include beyond-desktop {
    max-width: 575px;
  }

  @include mobile {
    max-width: 330px;

    margin: 0 auto;
  }
}

.features-one-title-image {
  display: inline-block;
  width: 45px;

  margin: 0 6px;
  vertical-align: middle;
}

.features-one-img {
  width: 100%;
}

.features-two {
  @include flex(center, flex-start);
  position: relative;
  width: 100%;

  @include mobile {
    @include flex(center, center);
  }
}

.features-two-img {
  width: 63%;

  @include mobile {
    width: 77%;
  }
}

.features-three {
  position: relative;
  width: 100%;

  @include mobile {
    margin-top: -30px;
  }
}

.features-three-img {
  position: relative;
  width: 100%;

  z-index: 2;

  @include mobile {
    width: calc(100% + 130px);
    margin: 0 -70px 0 -60px;
  }
}


//endregion

//region FEATURES2
.features2-section .features2-content {
  padding-top: 165px;
  padding-bottom: 165px;

  @include mobile {
    padding: 40px 20px;
  }
}

.features2-item {
  &:not(:last-of-type) {
    margin-bottom: 180px;
  }

  @include mobile {
    &:not(:last-of-type) {
      margin-bottom: 100px;
    }
  }
}

.features2-one {
  @include flex(center, center);

  position: relative;
  width: 100%;
}

.features2-one-title-main {
  @include mobile {
    @include font-barlow(50px,52px, $font-black);
    @include text-highlight(linear-gradient(63.46deg, #69C0FF 27%, #096DD9 78.28%));
  }
}

.features2-one-img {
  position: relative;
  width: 100%;
  max-width: 475px;

  z-index: 2;
}

.features2-two {
  @include flex(center, center);

  position: relative;
  width: 100%;
}

.features2-blur-1 {
  position: absolute;
  top: -300px;
  left: -300px;
  width: 800px;
}

.features2-two-title-main {
  @include mobile {
    @include font-barlow(50px,52px, $font-black);
    @include text-highlight(linear-gradient(63.46deg, #69C0FF 27%, #096DD9 78.28%));

    display: block;
  }
}

.features2-two-img {
  position: relative;
  width: 80%;
  max-width: 352px;

  z-index: 2;

  @include mobile {
    width: 300px;
  }
}

//endregion

//region KEY FEATURES
.keyfeats-section .keyfeats-content {
  @include mobile {
    padding: 40px 20px;
  }
}

.keyfeats-title {
  @include font-barlow(56px, 62px, $font-black);
  color: $blue-9;

  width: 540px;

  text-align: center;

  margin: 0 auto 16px;

  .highlight {
    @include text-highlight(linear-gradient(64.5deg, #1890FF 23.7%, #2D57E4 66.23%));
  }

  @include mobile {
    @include font-barlow(32px, 40px, $font-black);
    width: 100%;

    .highlight {
      @include font-barlow(50px, 52px, $font-black);
    }
  }
}

.keyfeats-list {
  @include flex(flex-start, center);
  flex-wrap: wrap;
  gap: 48px;

  width: 100%;
  max-width: 900px;

  margin: 64px auto 96px;

  @include tablet-portrait {
    margin-bottom: 164px;
  }
}
//endregion


//region FAQ
.faq-section .faq-content {
  @include mobile {
    padding: 40px 20px;
  }
}

.faq-title {
  @include font-barlow(56px, 62px, $font-black);
  color: $blue-9;

  width: 540px;

  text-align: center;

  margin: 0 auto 16px;

  .highlight {
    @include text-highlight(linear-gradient(64.5deg, #1890FF 23.7%, #2D57E4 66.23%));
  }

  @include mobile {
    @include font-barlow(32px, 40px, $font-black);
    width: 100%;

    .highlight {
      @include font-barlow(50px, 52px, $font-black);
    }
  }
}

.faq-list {
  @include flex(flex-start, center, column);
  gap: 24px;

  width: 100%;
  max-width: 900px;

  margin: 64px auto 96px;

  padding: 0 24px;
}
//endregion

//region HUMAN TOUCH
.touch-section .touch-content {
  @include mobile {
    padding: 40px 20px;
  }
}

.touch-category {
  @include font-lato(16px,24px);
  color: $blue-7;

  text-align: center;

  margin-bottom: 8px;
}

.touch-title {
  @include font-barlow(56px, 62px, $font-black);
  color: $blue-9;

  width: 540px;

  text-align: center;

  margin: 0 auto 16px;

  .highlight {
    @include text-highlight(linear-gradient(64.5deg, #1890FF 23.7%, #2D57E4 66.23%));
  }

  @include mobile {
    @include font-barlow(32px, 40px, $font-black);
    width: 100%;

    .highlight {
      @include font-barlow(50px, 52px, $font-black);
    }
  }
}

.touch-text {
  @include font-lato(20px, 32px);
  color: $gray-7;

  width: 100%;
  max-width: 950px;

  text-align: center;

  margin: 0 auto 16px;

  @include mobile {
    @include font-lato(20px, 32px);
    width: 100%;

    margin-bottom: 60px;
  }
}

//.touch-image {
//  display: block;
//
//  width: 80%;
//  margin: 0 auto;
//
//  @include beyond-desktop {
//  }
//
//  @include mobile {
//    display: none;
//  }
//}
//
//.touch-image-mobile {
//  display: none;
//
//  @include mobile {
//    display: block;
//    width: 100%;
//    max-width: 360px;
//
//    margin: 0 auto;
//  }
//}

.touch-media {
  position: relative;
  display: block;

  width: 80%;
  margin: 0 auto;

  @include mobile {
    display: none;
  }
}

.touch-media-mobile {
  position: relative;
  display: none;

  width: 100%;
  margin: 0 auto;

  @include mobile {
    display: block;
  }
}

.touch-image {
  width: 100%;
}

@keyframes touch-m1 {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  25% { // 2000
    opacity: 0;
    transform: translateY(50%);
  }
  31.25% { // 2500
    opacity: 1;
    transform: translateY(0);
  }
  93.75% { // 7500
    opacity: 1;
    transform: translateY(0);
  }
  100% { // 8000
    opacity: 0;
    transform: translateY(-50%);
  }
}

@keyframes touch-p1 {
  0%, 31.25% { // 0 - 2500
    transform: translateY(0);
  }
  32.813% { // 2625
    transform: translateY(-25%);
  }
  34.375% { // 2750
    transform: translateY(0);
  }
  35.938% { // 2875
    transform: translateY(-15%);
  }
  37.5% { // 3000
    transform: translateY(0);
  }
  39.063% { // 3125
    transform: translateY(-7.5%);
  }
  40.462%, 100% { // 3250
    transform: translateY(0);
  }
}

@keyframes touch-m2 {
  0%, 43.75% { // 0 - 3500
    opacity: 0;
    transform: translateY(50%);
  }
  50% { // 4000
    opacity: 1;
    transform: translateY(0);
  }
  93.75% { // 7500
    opacity: 1;
    transform: translateY(0);
  }
  100% { // 8000
    opacity: 0;
    transform: translateY(-50%);
  }
}

@keyframes touch-m3 {
  0%, 62.5% { // 0 - 5000
    opacity: 0;
    transform: translateY(50%);
  }
  68.75% { // 5500
    opacity: 1;
    transform: translateY(0);
  }
  93.75% { // 7500
    opacity: 1;
    transform: translateY(0);
  }
  100% { // 8000
    opacity: 0;
    transform: translateY(-50%);
  }
}

@keyframes touch-p3 {
  0%, 68.75% { // 0 - 5500
    transform: translateY(0);
  }
  70.313% { // 5625
    transform: translateY(-25%);
  }
  71.875% { // 5750
    transform: translateY(0);
  }
  73.438% { // 5875
    transform: translateY(-15%);
  }
  75% { // 6000
    transform: translateY(0);
  }
  76.563% { // 6125
    transform: translateY(-7.5%);
  }
  78.125%, 100% { // 6250 - 8000
    transform: translateY(0);
  }
}

@mixin animate($name, $duration: 8000ms) {
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.touch-msg {
  position: absolute;
  opacity: 0;
}

.msg-1 {
  width: 16.6%;
  top: 19.7%;
  left: 24.5%;

  opacity: 0;

  @include animate(touch-m1);
}

.msg-2 {
  width: 16.1%;
  top: 28.5%;
  left: 59%;

  @include animate(touch-m2);
}

.msg-3 {
  width: 16.6%;
  top: 37.3%;
  left: 24.5%;

  @include animate(touch-m3);
}

.touch-plat {
  position: absolute;

  top: 6.16%;

  width: 2.19%;
}
.plat-1 {
  left: 64.1%;
}
.plat-2 {
  left: 65.7%;
}
.plat-3 {
  left: 67.3%;

  @include animate(touch-p1);
}
.plat-4 {
  left: 68.9%;
}
.plat-5 {
  left: 70.5%;
}
.plat-6 {
  left: 72.1%;

  @include animate(touch-p3);
}

.touch-media-mobile {
  .msg-1 {
    width: 40%;
    top: 22.28%;
    left: 5.25%;
  }

  .msg-2 {
    width: 38.77%;
    top: 34.54%;
    left: 55.62%;
  }

  .msg-3 {
    width: 40%;
    top: 46.24%;
    left: 5.25%;
  }

  .touch-plat {
    top: 3.5%;

    width: 5.538%;
  }
  .plat-1 {
    left: 70.77%;
  }
  .plat-2 {
    left: 74.46%;
  }
  .plat-3 {
    left: 78.15%;
  }
  .plat-4 {
    left: 81.84%;
  }
  .plat-5 {
    left: 85.53%;
  }
  .plat-6 {
    left: 89.22%;
  }
}

//endregion

